import { useState } from 'react';
import NavBar from './components/Navbar';
import Sidebar from './components/Sidebar';
import ViewAllStudents from './components/ViewAllStudents';
import ViewAllAttendees from './components/ViewAllAttendees';
import SelectBatch from './components/SelectBatch';

function Dashboard() {
  const [ viewallstudents, setviewallstudents] = useState(true);
  const [ viewattendees, setviewattendees ] = useState(false); 
  const [selectedBranchOption, setSelectedBranchOption] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [searchRoll, setSearchRoll] = useState('')

  return (
    <div>
      <NavBar></NavBar>
      <SelectBatch selectedBranchOption={selectedBranchOption} selectedBatch={selectedBatch} selectedTime={selectedTime} setSelectedBranchOption={setSelectedBranchOption} setSelectedBatch={setSelectedBatch} setSelectedTime={setSelectedTime} searchRoll={searchRoll} setSearchRoll={setSearchRoll}></SelectBatch>
      <div className='grid grid-cols-1 p-4 md:p-12 gap-4 md:grid-cols-3 md:gap-8' >
      <div className=''>
        <Sidebar setviewallstudents={setviewallstudents} setviewattendees={setviewattendees}></Sidebar>
      </div>
      <div className='col-span-2'>
        { viewallstudents && <ViewAllStudents selectedBranchOption={selectedBranchOption} selectedBatch={selectedBatch} selectedTime={selectedTime} searchRoll={searchRoll}></ViewAllStudents>  }
        {viewattendees && <ViewAllAttendees></ViewAllAttendees> }
      </div>
    </div>  
    </div>
  );
}

export default Dashboard;
