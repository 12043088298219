import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';

const MakeAttendenceSheet = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ examName, setExamName] = useState('');
  const [date, setDate] = useState('');
  const [ batchName, setBatchName] = useState('');

  const batches = ["Engineering", "Varsity", "Medical"]

  const branch = JSON.parse(localStorage.getItem('branch'));

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'px',
    },
  };

  Modal.setAppElement('#root');

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const createSheet = () => {
    if (examName !== '' && date !== '') {
      const id = toast.loading("creating attendence sheet...")
      const fetchData = async () => {
        try {
          const response = await fetch('https://branch-manager-backend.onrender.com/create-sheet', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                exam: examName,
                date: date,
                batch: batchName,
                branch: branch
            })
          });
          const jsonData = await response.json();
          toast.update(id, { render: `${jsonData.message}`, type: "success", isLoading: false, autoClose: 2000 }); 
          closeModal()
        } catch (error) {
          toast.update(id, { type: "error", autoClose: 3000, render: "Couldn't create sheet" , isLoading: false })
        }
      };
      fetchData()
    }
    else {
      toast.error("Please enter all correct information!")
    }
  }


  return (
    <div className='flex items-center justify-center h-screen'>
      <ToastContainer position="top-center" autoClose={1500} limit={1}/>
      <div>
        <p className='text-center font-bold text-xl'>
          Welcome, Manager!
        </p>
        <br />
        <button onClick={() => openModal()} className='bg-stone-300 py-2 px-8 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110'>Make attendence sheet</button>
        <br />
        <br />
        <button onClick={() => navigate('/dashboard')} className='bg-stone-400 py-2 px-8 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110'> View attendance sheet</button>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='p-4 md:p-16 shadow-2xl'>
      <p>Enter Exam Name: </p> 
      <input type="text" placeholder="" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4" value={examName} onChange={(e) => { setExamName(e.target.value) }} required />
      <p>Enter Date in correct format: </p>
      <input type="text" placeholder="DD-MM-YY" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4" value={date} onChange={(e) => { setDate(e.target.value) }} required />
      <p>Enter batch:</p>
      <select
        value={batchName}
        onChange={(e) => setBatchName(e.target.value)} 
        className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-full p-2 my-4"
      >
        <option value="">Select a Batch</option>
        {batches.map((batch, index) => (
          <option key={index} value={batch}>
            {batch}
          </option>
        ))}
      </select>
          
          <button onClick={() => createSheet()} className='bg-stone-400 p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110'>Make Sheet</button>
        </div>
      </Modal>
      </div>
    </div>
  );
};

export default MakeAttendenceSheet;