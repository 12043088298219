import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ViewAllStudents = ({selectedBranchOption, selectedTime, searchRoll, selectedBatch}) => {
  const [sheetData, setSheetData] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://branch-manager-backend.onrender.com/sheet')
    .then(response => response.json())
    .then(data => setSheetData(data))
    .catch(error => console.error('Error fetching user:', error));
  },[])

  return (
    <div className="">
      <br />
      <p className='font-bold text-xl text-center'>All Attendence sheets</p>
      <br />
      {sheetData.length > 0 ? (
        sheetData.map((data) => (
          <div key={data.id} className="bg-white shadow-md rounded-md p-4 mb-4 flex flex-col md:flex-row justify-center items-center md:justify-between">
            <p>{data.date}</p>
            <div>
              <h2 className="text-lg font-semibold">{data.exam} </h2>
              <p className="text-gray-600">{data.batch}</p>
            </div>
            <div className=''>
              <button onClick={() => navigate(`/scanner?exam=${data._id}`)} className='bg-stone-400  p-2 rounded-lg transition-transform duration-300 transform hover:scale-110 shadow-lg'>Give attendance</button>
            </div>
          </div>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ViewAllStudents;