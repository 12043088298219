import React from 'react';

const ViewAllAttendees = () => {
  return (
    <div>
      view all ateendees that are attending this exam!!
    </div>
  );
};

export default ViewAllAttendees;