import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

const SelectBatch = ({setSelectedBranchOption, setSelectedBatch, setSelectedTime, selectedBranchOption, selectedBatch, selectedTime, searchRoll, setSearchRoll}) => {
  
  const cities = ["New York City", "Tokyo", "London", "Paris", "Sydney"];
  const batches = ["Engineering", "Varsity", "Medical"]
  const times = ["10.00 am", "12.00 am", "4.00 pm", "6.00 pm"]

  const handleChange = event => {
    setSelectedBranchOption(event.target.value);
  };
  const handleBatchChange = event => {
    setSelectedBatch(event.target.value);
  };
  const handleTimeChange = event => {
    setSelectedTime(event.target.value);
  };
  const handleRollChange = event => {
    setSearchRoll(event.target.value);
  };


  return (
    <div className='px-4 md:px-12 mt-12 grid grid-cols-2 md:grid-cols-6 gap-8'>
      <select
        value={selectedBranchOption}
        onChange={handleChange}
        className=" appearance-none border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow focus:outline-none focus:shadow-outline"
      >
        <option value="">Select a City</option>
        {cities.map((city, index) => (
          <option key={index} value={city}>
            {city}
          </option>
        ))}
      </select> 
      <select
        value={selectedBatch}
        onChange={handleBatchChange}
        className=" appearance-none border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow focus:outline-none focus:shadow-outline"
      >
        <option value="">Select a Batch Time</option>
        {batches.map((batch, index) => (
          <option key={index} value={batch}>
            {batch}
          </option>
        ))}
      </select>
      <select
        value={selectedTime}
        onChange={handleTimeChange}
        className=" appearance-none border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow focus:outline-none focus:shadow-outline"
      >
        <option value="">Select Time slot</option>
        {times.map((time, index) => (
          <option key={index} value={time}>
            {time}
          </option>
        ))}
      </select>
      <div className="flex items-center border border-gray-500 col-span-2 rounded-lg px-3 py-2">
      <AiOutlineSearch size={20} />
      <input
        type="text"
        placeholder="Search" onChange={handleRollChange}
        className="flex-grow ml-2 outline-none placeholder-gray-500"
      />
    </div>
    </div>
  );
};

export default SelectBatch;
