import React from 'react';
import { PiStudent } from "react-icons/pi";
import { RiFileList3Line } from "react-icons/ri";
import { BsQrCodeScan } from "react-icons/bs";
import { Link } from "react-router-dom";

const Sidebar = ({setviewallstudents, setviewattendees }) => {

  const handleviewallstudents = () => {
    setviewallstudents(true)
    setviewattendees(false)
  }

  const handleviewattendees = () => {
    setviewattendees(true)
    setviewallstudents(false)
  }

  return (
    <div className='bg-stone-300 p-8 rounded-2xl'>
      <Link to="/scanner">
      <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' > <BsQrCodeScan className='inline-block' size={20}/> Scan QR Code </button>
      </Link>

      <br />
      <br />
      <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewallstudents}> <PiStudent className='inline-block' size={24}/> See All enrolled students</button>
      <br />
      <br />
      <button className='bg-white p-2 shadow-2xl rounded-xl transition-transform duration-300 transform hover:scale-110' onClick={handleviewattendees} > <RiFileList3Line className='inline-block' size={24}/> See Attendees for today's exam </button>
      
    </div>
  );
};

export default Sidebar;